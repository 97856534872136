body {
  margin: 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.imageWhite {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.healthBarFirst {
  transform: skew(30deg);
}
.healthBarSecond {
  transform: skew(-30deg) scaleX(-1);
}
.healthBar {
  border: black solid 1px;
  background-color: black;
}

.reverseFlexRow {
  flex-direction: row-reverse;
}

.damageLogFirstPlayer {
  display: flex;
  justify-content: flex-end;
  text-align: left;
}
.damageLogSecondPlayer {
  display: flex;
  justify-content: start;
  text-align: right;
}

.red {
  color: red;
}
.green {
  color: green;
}
.blue {
  color: deepskyblue;
}
